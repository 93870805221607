<template>
  <div ref="chatConversation" class="chat-conversation">
    <div
      ref="chatScrollButton"
      @click="scrollToBottom"
      class="chat-conversation__button"
      role="button"
    >
      <span class="chat-conversation__icon">
        <chevron-down-icon />
      </span>
    </div>
    <vuescroll :ops="ops" ref="scrollBody" @handle-scroll="handleScroll">
      <div ref="chatBody" class="chat-conversation__body">
        <div class="chat-conversation__filler"></div>
        <chat-loader v-if="showLoader" />
        <template v-for="(message, index) in messages">
          <message-item
            :id="message.id"
            :key="`d-${message.id}-start`"
            :message="getDateHeaderMessage(index + 1)"
            v-if="isTopReached(index)"
          />
          <message-item
            :id="message.id"
            :key="message.id ? message.id : message.tempId"
            :message="message"
            :has-next="
              hasNextMessage(message.typeUser, index + 1) ||
              showDateHeader(message.createdAt, index + 1)
            "
            :is-within-same-minute="
              isWithinSameMinute(message.typeUser, message.createdAt, index + 1)
            "
          />
          <message-item
            :id="message.id"
            :key="`d-${message.id}`"
            :message="getDateHeaderMessage(index + 1)"
            :has-next="hasNextMessage(message.typeUser, index + 1)"
            v-if="showDateHeader(message.createdAt, index + 1)"
          />
        </template>
      </div>
    </vuescroll>
  </div>
</template>

<script>
import { gsap } from 'gsap';
import vuescroll from 'vuescroll';
import { mapGetters } from 'vuex';
import {
  AUDIO_STOP_ALL,
  CONVERSATION_LOAD_MESSAGES,
  MESSAGES_LOADED,

} from '@/eventTypes';
import EventBus from '@/eventBus';

export default {
  components: {
    vuescroll,
    ChatLoader: () => import('@/components/chat/ChatLoader.vue'),
    MessageItem: () => import('@/components/chat/MessageItem.vue'),
  },
  props: {
    clientId: {
      required: true,
      type: String,
    },
    messages: {
      required: true,
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      ops: {
        sizeStrategy: 500,
        bar: {
          size: '8px',
          minSize: 0.2,
          keepShow: true,
          background: '#b4cbff',
          specifyBorderRadius: '4px',
        },
        rail: {
          size: '8px',
          gutterOfSide: '0',
          specifyBorderRadius: '4px',
          keepShow: false,
          opacity: 0.5,
          background: '#d8d8d8',
        },
        scrollPanel: {
          scrollingX: false,
          easing: 'easeInOutQuad',
        },
      },
      animationScrollButton: {
        duration: 0.35,
        fromVars: {
          y: 21,
          opacity: 0,
          display: 'none',
        },
        toVars: {
          y: 0,
          opacity: 1,
          display: 'flex',
        },
      },
      scrollTop: 0,
      scrollHeight: 0,
      scrollProcess: 0,
      firstLoad: true,
      lastMessageLoadedId: '',
    };
  },
  computed: {
    ...mapGetters('chat', [
      'chatIsLoading',
      'chatFirstLoad',
      'chatTopReached',
      'chatOldestMessageId',
    ]),
    showLoader() {
      return this.chatIsLoading;
    },
    scrollFromBottom() {
      return this.scrollHeight - this.scrollTop;
    },
    showScrollButton() {
      return this.scrollFromBottom >= 400;
    },
    totalMessages() {
      return this.messages.length;
    },
  },
  watch: {
    clientId() {
      this.clientUpdated();
    },
    scrollTop(newValue, oldValue) {
      if (newValue <= 20 && newValue < oldValue) {
        this.lastMessageLoadedId = this.messages[0].id;
        this.loadMessages();
      }
    },
    showScrollButton(newValue) {
      if (newValue) {
        gsap.fromTo(this.$refs.chatScrollButton,
          this.animationScrollButton.duration,
          this.animationScrollButton.fromVars,
          this.animationScrollButton.toVars);
      } else {
        gsap.fromTo(this.$refs.chatScrollButton,
          this.animationScrollButton.duration,
          this.animationScrollButton.toVars,
          this.animationScrollButton.fromVars);
      }
    },
    totalMessages() {
      if (this.scrollProcess === 0 && this.firstLoad) {
        this.firstLoad = false;
        setTimeout(() => this.scrollToBottom(), 100);
      }

      if (this.scrollProcess === 1) {
        this.scrollToBottom();
      }
      // document.getElementById(this.lastMessageLoadedId).scrollIntoView();
    },
  },
  created() {
    EventBus.$on(MESSAGES_LOADED, (clientId) => {
      if (this.clientId === clientId && this.$refs.scrollBody) {
        this.$nextTick(() => {
          this.$refs.scrollBody.refresh();
        });
        this.keepScrollAt(this.scrollFromBottom);
      }
    });
  },
  mounted() {
    this.clientUpdated();
  },
  destroyed() {
    EventBus.$off(MESSAGES_LOADED);
  },
  methods: {
    isTopReached(index) {
      return this.chatTopReached && index === 0;
    },
    clientUpdated() {
      this.stopAudio();

      if (this.chatFirstLoad(this.clientId)) {
        this.loadMessages();
      }

      this.scrollHeight = 0;
    },
    hasNextMessage(typeUser, nextIndex) {
      return this.messages[nextIndex] && this.messages[nextIndex].typeUser === typeUser;
    },
    isWithinSameMinute(typeUser, createdAt, nextIndex) {
      if (this.messages[nextIndex]) {
        return this.messages[nextIndex].typeUser === typeUser
          && this.isSame(this.messages[nextIndex].createdAt, createdAt, 'minute');
      }

      return false;
    },
    isSame(date1, date2, granularity) {
      if (date1 && date2) {
        const d2 = this.$moment(date2).local();
        return this.$moment(date1).local().isSame(d2, granularity);
      }

      return false;
    },
    showDateHeader(createdAt, nextIndex) {
      if (this.messages[nextIndex]) {
        return createdAt && this.messages[nextIndex].createdAt
          && !this.isSame(this.messages[nextIndex].createdAt, createdAt, 'day');
      }

      return false;
    },
    getDateHeaderMessage(nextIndex) {
      let relativeTimestamp = '';

      if (this.messages[nextIndex]) {
        relativeTimestamp = this.messages[nextIndex].createdAt;
      }

      return {
        relativeTimestamp,
        hideTimestamp: true,
        typeUser: null,
      };
    },
    handleScroll(v) {
      this.scrollTop = v.scrollTop;
      this.scrollProcess = v.process;

      this.updateScrollHeight();
    },
    updateScrollHeight() {
      const scrollHeight = this.$refs.chatBody ? this.$refs.chatBody.scrollHeight : 0;
      const clientHeight = this.$refs.chatConversation
        ? this.$refs.chatConversation.clientHeight : 0;

      this.scrollHeight = scrollHeight - clientHeight;
    },
    scrollToBottom() {
      this.$nextTick(() => {
        this.$refs.scrollBody.scrollTo({ y: '100%' }, 0);
      });
    },
    scrollToPosition(position) {
      this.$nextTick(() => {
        this.$refs.scrollBody.scrollTo({ y: position }, 0);
      });
    },
    keepScrollAt(initialY) {
      this.$nextTick(() => {
        const { scrollHeight } = this.$refs.chatBody;
        const { clientHeight } = this.$refs.chatConversation;
        const newY = scrollHeight - clientHeight - initialY;

        this.scrollToPosition(newY);
      });
    },
    loadMessages() {
      if (!this.chatIsLoading && !this.chatTopReached) {
        const oldestId = this.chatOldestMessageId;
        const conversation = this.messages[0]?.conversation.id;
        const clientId = this.messages[0]?.conversation.client;
        EventBus.$emit(CONVERSATION_LOAD_MESSAGES, clientId, oldestId, conversation);
      }
    },
    stopAudio() {
      EventBus.$emit(AUDIO_STOP_ALL);
    },

  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/chat/_chat.scss";
</style>
